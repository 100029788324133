var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
    'esy-button',
    `esy-button--variant-${_vm.variant}`,
    `esy-button--${_vm.size}`,
    {
      'esy-button--full-width': _vm.fullWidth,
      'esy-button--loading': _vm.loading,
    },
  ],attrs:{"disabled":_vm.loading}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }