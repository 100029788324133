
import { Component, Prop, Vue } from "nuxt-property-decorator";

// the deprecated variants should be removed over time
const deprecatedVariants = [
  "micro",
  "small",
  "small-secondary",
  "white-ghost",
  "white-large",
  "yellow-large",
];

const variants = [
  ...deprecatedVariants,
  "default",
  "white",
  "gray",
  "yellow",
  "black",
  "ghost",
  "icon",
  "text",
] as const;

type ButtonVariant = typeof variants[number];

const sizes = ["default", "micro", "small", "big", "large"] as const;
type ButtonSize = typeof sizes[number];

@Component({})
export default class Button extends Vue {
  @Prop({ default: "default" }) size: ButtonSize;
  @Prop({ default: "default" }) variant: ButtonVariant;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) fullWidth: boolean;

  constructor() {
    super();
    if (this.$nuxt.context.isDev) {
      if (!sizes.includes(this.size)) {
        console.warn(`Unknown value for button size: '${this.size}'`);
      }
      if (!variants.includes(this.variant)) {
        console.warn(`Unknown value for button variant: '${this.variant}'`);
      }
      if (deprecatedVariants.includes(this.variant)) {
        console.warn(
          `Used variant '${this.variant}' is deprecated.
Consider using one of the following: [${variants
            .filter((v) => !deprecatedVariants.includes(v))
            .join(", ")}]
in combination with the "size" prop.
        `,
        );
      }
    }
  }
}
